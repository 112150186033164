import { useEffect } from "react";

// Allows modal components not lock the main body scrolling, so only the modal content scolls.
// NOTE: expects there to be .no-scroll class defined in index.css
export function useBackgroundLock(): void {
  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      // make scrollable again, when unmounting the modal component
      document.body.classList.remove("no-scroll");
    };
  }, []);
}
