import React, { useEffect } from "react";
import styled from "styled-components";
import { defaultGrey, filterGreen, greyTransparent, valmetGreyBorder } from "../../../../../common/colors";
import { ActionButton, ButtonContainer } from "../../../../../common/components";
import { useBackgroundLock } from "../../../../../hooks/useBackgroundLock";

export interface ErrorDialogProps {
  errors: string[];
  onClose: () => void;
}

function ErrorDialog(props: ErrorDialogProps): React.ReactElement {
  const { errors, onClose } = props;

  useBackgroundLock();

  return (
    <FullscreenWrapper>
      <Container>
        <ContentContainer>
          <TitleContainer>Error</TitleContainer>
          {errors.map((error, i) => (
            <ContentSection key={i}>{error}</ContentSection>
          ))}
          <ContentSection>
            <ButtonContainer>
              <ActionButton onClick={() => onClose()}>Close</ActionButton>
            </ButtonContainer>
          </ContentSection>
        </ContentContainer>
      </Container>
    </FullscreenWrapper>
  );
}

export default ErrorDialog;

const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  background: ${greyTransparent};
  overflow-y: hidden;
`;

const Container = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border: 1px solid ${valmetGreyBorder};
  z-index: 1500;
  font-size: 24px;
  color:${defaultGrey}
  padding-top:20px;
  padding-bottom:20px;
  padding-left:25px;
  padding-right:25px;
  button:hover {
    background: ${filterGreen};
  }
  width: 675px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div``;

const ContentSection = styled.div`
  margin-top: 20px;
  font-size: 16px;
`;
