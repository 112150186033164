import React from "react";
import {
  ActivityEstimateCommentTypeId,
  ActivityId,
  CostComponentEditRow,
  CurrencyScenario,
  EstimateCodeSelectionState,
  EstimateStatuses,
  ProjectCostActivityComment,
  ProjectCostSummaryRow,
  ProjectCostValues,
} from "../../../../../common/types";
import { DataCell, ReadOnlyValue, getNumericValue, getValue, SummaryDataRow } from "./util";
import CostComponentRow from "./CostComponentRow";
import produce from "immer";
import CostActivityComment from "./CostActivityComment";
import styled from "styled-components";
import { commentBackgroundBlue, commentBlue } from "../../../../../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { cloneDeep } from "lodash";
import { IconButton } from "../../../../../common/components";
import { CostsHeader } from "../types";

export interface CostsSummaryRowProps {
  row: ProjectCostSummaryRow;
  projectId: number;
  comments: ProjectCostActivityComment[] | undefined;
  setComments: (comments: ProjectCostActivityComment[]) => void;
  commentTypes: ActivityEstimateCommentTypeId[];
  displayComments: boolean;
  setDisplayComments: (id: ActivityId | undefined) => void;
  estimateStatuses: EstimateStatuses;
  estimateCodesSelectionState: EstimateCodeSelectionState;
  userCanEdit: boolean;
  selectedCurrencyScenario: CurrencyScenario;
  onChangeComponentRows: (activityId: ActivityId, rows: CostComponentEditRow[]) => void;
  errors: { [field: string]: boolean };
  setErrors: (errors: { [field: string]: boolean }) => void;
  selectedActivityId: string;
  setSelectedActivityId: (id: string) => void;
  summaryHeaders: CostsHeader[];
}

function CostSummaryRow(props: CostsSummaryRowProps): React.ReactElement {
  const {
    row,
    projectId,
    comments,
    setComments,
    commentTypes,
    displayComments,
    setDisplayComments,
    estimateStatuses,
    estimateCodesSelectionState,
    userCanEdit,
    selectedCurrencyScenario,
    onChangeComponentRows,
    errors,
    setErrors,
    selectedActivityId,
    setSelectedActivityId,
    summaryHeaders,
  } = props;
  const values: ProjectCostValues = {
    ...row.values,
    ...row.summaryValues,
  };

  const onChangeRow = (index: number, newComponentRow: CostComponentEditRow) => {
    row.components &&
      row.components.length > 0 &&
      onChangeComponentRows(
        row.id,
        produce(row.components, nextState => {
          nextState[index] = newComponentRow;
        })
      );
  };

  function setComment(key: number, newComment: ProjectCostActivityComment) {
    const newComments = comments ? cloneDeep(comments) : [];
    if (key < newComments.length) {
      newComments[key] = newComment;
    } else {
      newComments.push(newComment);
    }
    setComments(newComments);
  }

  function removeComment(key: number) {
    if (comments && key < comments.length) {
      const newComments = cloneDeep(comments);
      newComments.splice(key, 1);
      setComments(newComments);
    }
  }

  const subtract = (a: number | null | undefined, b: number | null | undefined): number => (a || 0) - (b || 0);

  return (
    <>
      <SummaryDataRow
        key={row.id}
        childLevel={row.childLevel}
        isLeaf={!row.childItemIds || row.childItemIds.length === 0}
        selected={selectedActivityId === row.id}
        onClick={() => setSelectedActivityId(row.id)}
      >
        <DataCell>
          <ActivityDescription hasComments={!!comments}>
            {row.description}
            <IconButton onClick={() => (displayComments ? setDisplayComments(undefined) : setDisplayComments(row.id))}>
              <FontAwesomeIcon icon={faCommentDots} color={commentBlue} />
            </IconButton>
          </ActivityDescription>
        </DataCell>
        {summaryHeaders.map((header, i) => (
          <DataCell
            key={`${row.id}_${i}`}
            warning={
              header.id === "ETC" && getNumericValue(subtract(values["currentEstimate"], values["committed"])) < -1000
            }
          >
            {header.id === "estimateChange" ? (
              <ReadOnlyValue
                value={getValue(subtract(values["currentEstimate"], values["prevEstimate"]))}
                disabled={true}
              />
            ) : header.id === "ETC" ? (
              <ReadOnlyValue
                value={getValue(subtract(values["currentEstimate"], values["committed"]))}
                disabled={true}
              />
            ) : (
              <ReadOnlyValue value={getValue(values[header.id])} disabled={true} />
            )}
          </DataCell>
        ))}
      </SummaryDataRow>
      {displayComments && (
        <CommentsRow>
          <CommentsCell colSpan={summaryHeaders.length + 1}>
            <CommentsContainer commentsVisible={displayComments}>
              {comments &&
                comments.map((comment, i) => (
                  <CostActivityComment
                    key={i}
                    projectId={projectId}
                    activityId={row.id}
                    estimateCodeId={
                      estimateCodesSelectionState.currentEstimate
                        ? estimateCodesSelectionState.currentEstimate.id
                        : undefined
                    }
                    setComment={newComment => setComment(i, newComment)}
                    deleteComment={() => removeComment(i)}
                    comment={comment}
                    commentTypes={commentTypes}
                  />
                ))}
              <CostActivityComment
                projectId={projectId}
                activityId={row.id}
                estimateCodeId={
                  estimateCodesSelectionState.currentEstimate
                    ? estimateCodesSelectionState.currentEstimate.id
                    : undefined
                }
                setComment={newComment => setComment(comments ? comments.length : 0, newComment)}
                commentTypes={commentTypes}
              />
            </CommentsContainer>
          </CommentsCell>
        </CommentsRow>
      )}
      {row.components &&
        row.components.map((component, i) => (
          <CostComponentRow
            key={i}
            activityId={row.id}
            row={component}
            childLevel={row.childLevel + 1}
            estimateStatuses={estimateStatuses}
            userCanEdit={userCanEdit}
            selectedCurrencyScenario={selectedCurrencyScenario}
            onChangeRow={newRow => onChangeRow(i, newRow)}
            errors={errors}
            setErrors={setErrors}
            summaryHeaders={summaryHeaders}
          />
        ))}
    </>
  );
}

export default CostSummaryRow;

const ActivityDescription = styled.div<{ hasComments: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    visibility: ${({ hasComments }) => (hasComments ? "visible" : "hidden")};
  }
  &:hover {
    button {
      visibility: visible;
    }
  }
`;

const CommentsRow = styled.tr`
  overflow: hidden;
`;

const CommentsCell = styled.td``;

const CommentsContainer = styled.div<{ commentsVisible: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
  background-color: ${commentBackgroundBlue};
  ${({ commentsVisible }) => !commentsVisible && "max-height: 0"};
  transition: max-height 1s;
  align-items: center;
`;
