import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  ActivityEstimateCommentTypeId,
  ActivityId,
  CostActivityEstimateChange,
  CostComponentEditRow,
  CostCurrencyScenario,
  CostEditValues,
  CurrencyScenario,
  EstimateCodeSelectionState,
  EstimateStatuses,
  ProjectCostActivityDataPoint,
  ProjectCostColumnId,
  ProjectCostCommentItem,
  ProjectCostComponentItems,
  ProjectCostEditColumnId,
  ProjectCostItem,
  ProjectCostSummaryRow,
} from "../../../../../common/types";
import { useApolloClient, useQuery } from "@apollo/client/react/hooks";
import { ApolloClient } from "@apollo/client";
import {
  GET_ESTIMATE_COMMENT_TYPES,
  GET_PROJECT_COST_ACTIVITY_DATA_POINTS,
  GET_PROJECT_COST_COMMENTS,
  GET_PROJECT_COST_COMPONENTS,
  saveProjectCostComponents,
} from "../queries";
import { cloneDeep, forEach } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import CopyDialog from "./CopyDialog";
import styled from "styled-components";
import { defaultGrey, disabledGrey, settingGreen, valmetGreyLight } from "../../../../../common/colors";
import FullscreenSpinner from "../../../../FullscreenSpinner";
import { EDITABLE_COST_COMPONENTS } from "../constants";
import CostItemsTable from "./CostItemsTable";
import produce from "immer";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import CostActualsCommittedChart from "./CostActualsCommittedChart";
import { ButtonContainer, IconButton } from "../../../../../common/components";
import MessagesDialog from "./MessagesDialog";
import { useBackgroundLock } from "../../../../../hooks/useBackgroundLock";

export interface CostItemsEditorProps {
  projectId: number;
  projectDescription: string;
  projectRelatingKey1: string;
  topActivity: ProjectCostItem;
  onCancel: () => void;
  onSave: () => void;
  estimateStatuses: EstimateStatuses;
  estimateCodesSelectionState: EstimateCodeSelectionState;
  userCanEdit: boolean;
  currentEstEditBlocked: boolean;
  selectedCurrencyScenario: CurrencyScenario;
}

function convertToEditRow(childLevel: number, activity: ProjectCostItem): ProjectCostSummaryRow {
  return {
    id: activity.id,
    description: activity.description,
    summaryValues: {
      revisedBudget: null,
      currentEstimate: null,
      estimatedHours: null,
      originalBudget: null,
      asSold: null,
    },
    values: activity.values,
    isEditingBlocked: activity.tooltip === null ? true : activity.tooltip.isBlocked,
    currencyRate: activity.currencyRate,
    childLevel: childLevel,
    childItemIds: activity.childItems && activity.childItems.map(item => item.id),
  };
}

const convertAllEditRows = (childLevel: number, activity: ProjectCostItem): ProjectCostSummaryRow[] => {
  const allRows = [convertToEditRow(childLevel, activity)];
  const rowsList: ProjectCostSummaryRow[][] | undefined =
    activity.childItems && activity.childItems.length > 0
      ? activity.childItems.map(item => convertAllEditRows(childLevel + 1, item))
      : undefined;
  if (rowsList) {
    rowsList.forEach(rows => rows.forEach(row => allRows.push(row)));
  }
  return allRows;
};

const calculateCurrency = (value: number | null, currencyRate: number): number | null => {
  return value && currencyRate !== 0 ? value / currencyRate : value;
};

function CostItemsEditor(props: CostItemsEditorProps) {
  const {
    onCancel,
    onSave,
    projectId,
    projectDescription,
    projectRelatingKey1,
    topActivity,
    estimateStatuses,
    estimateCodesSelectionState,
    userCanEdit,
    currentEstEditBlocked,
    selectedCurrencyScenario,
  } = props;

  const [errors, setErrors] = useState<{ [field: string]: boolean }>({});
  const [saving, setSaving] = useState<boolean>(false);
  const [showCopyDialog, setShowCopyDialog] = useState<boolean>(false);
  const [messagesDialog, setMessagesDialog] = useState<{ onOk: () => void; messages: string[] } | undefined>();
  const [savingError, setSavingError] = useState<boolean>(false);

  const targetRef: null | React.RefObject<HTMLDivElement> = useRef(null);
  const [contentWidth, setContentWidth] = useState(0);

  let resizeTimer: number | undefined = undefined;

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  const checkContentWidth = () => {
    const targetRefCurrent = targetRef.current;
    if (targetRefCurrent) {
      setContentWidth(targetRefCurrent.offsetWidth);
    }
  };

  useBackgroundLock();

  useLayoutEffect(() => {
    checkContentWidth();
  }, []);

  useLayoutEffect(() => {
    const handler = () => {
      clearInterval(resizeTimer);
      resizeTimer = setTimeout(checkContentWidth, 100);
    };
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  });

  const [allActivityEditRows, setAllActivityEditRows] = useState<ProjectCostSummaryRow[]>(
    convertAllEditRows(0, topActivity)
  );
  const [activityComments, setActivityComments] = useState<ProjectCostCommentItem[]>([]);
  const [selectedActivityId, setSelectedActivityId] = useState<string>(topActivity.id);

  const sum = (a: number, b: number): number => a + b;
  const calculateSummaryValues = (editState: CostEditValues[]): CostEditValues => {
    return {
      revisedBudget: editState.map(v => v.revisedBudget || 0).reduce(sum, 0),
      currentEstimate: editState.map(v => v.currentEstimate || 0).reduce(sum, 0),
      estimatedHours: editState.map(v => v.estimatedHours || 0).reduce(sum, 0),
      originalBudget: editState.map(v => v.originalBudget || 0).reduce(sum, 0),
      asSold: editState.map(v => v.asSold || 0).reduce(sum, 0),
    };
  };

  function calculateComponentsAndSummaries(
    activityEditRows: ProjectCostSummaryRow[],
    activityId: ActivityId,
    updateComponents: CostComponentEditRow[]
  ) {
    const newActivityEditRows = cloneDeep(activityEditRows);
    const rowIndex = newActivityEditRows.findIndex(item => item.id === activityId);
    if (rowIndex > -1) {
      newActivityEditRows[rowIndex].components = updateComponents;
      newActivityEditRows[rowIndex].summaryValues = calculateSummaryValues(
        updateComponents.map(item => item.editValues)
      );
      if (rowIndex !== 0) updateParentSummary(activityId, newActivityEditRows);
    }
    return newActivityEditRows;
  }

  function updateParentSummary(childActivityId: ActivityId, newActivityEditRows: ProjectCostSummaryRow[]) {
    const parentRowIndex = newActivityEditRows.findIndex(
      row => row.childItemIds && row.childItemIds.includes(childActivityId)
    );
    if (parentRowIndex > -1) {
      const parentRow = newActivityEditRows[parentRowIndex];
      const summaryValuesList = newActivityEditRows
        .filter(row => parentRow.childItemIds?.includes(row.id))
        .map(row => row.summaryValues);
      newActivityEditRows[parentRowIndex].summaryValues = calculateSummaryValues(summaryValuesList);
      if (parentRowIndex !== 0) {
        updateParentSummary(newActivityEditRows[parentRowIndex].id, newActivityEditRows);
      }
    }
  }

  const currentEstimateCodeId = estimateCodesSelectionState.currentEstimate
    ? estimateCodesSelectionState.currentEstimate.id
    : undefined;
  const prevEstimateCodeId = estimateCodesSelectionState.prevEstimate
    ? estimateCodesSelectionState.prevEstimate.id
    : undefined;
  const revisedBudgetEstimateCodeId = estimateCodesSelectionState.revisedBudget
    ? estimateCodesSelectionState.revisedBudget.id
    : undefined;

  const { data: estimateCommentTypesResult } = useQuery<{ estimateCommentTypes: ActivityEstimateCommentTypeId[] }>(
    GET_ESTIMATE_COMMENT_TYPES
  );

  const allActivityIds = allActivityEditRows.map(row => row.id);
  const { loading: commentsLoading, error: commentsError } = useQuery<{
    projectCostComments?: ProjectCostCommentItem[];
  }>(GET_PROJECT_COST_COMMENTS, {
    skip: !currentEstimateCodeId,
    variables: {
      projectId,
      activityIds: allActivityIds,
      estimateCodeId: currentEstimateCodeId,
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.projectCostComments) {
        const editCommentItems = data.projectCostComments.map(item => {
          return {
            activityId: item.activityId,
            comments: item.comments.map(comment => {
              return {
                commentId: comment.commentId,
                commentType: comment.commentType,
                commentText: comment.commentText,
                originalText: comment.commentText,
                modifiedDate: comment.modifiedDate,
                modifiedBy: comment.modifiedBy,
              };
            }),
          };
        });
        setActivityComments(editCommentItems);
      }
    },
  });

  const leafActivityIds = allActivityEditRows
    .filter(item => !item.childItemIds || item.childItemIds.length === 0)
    .map(item => item.id);
  const { data, loading, error: loadingErrors } = useQuery<{
    projectCostComponents?: ProjectCostComponentItems[];
  }>(GET_PROJECT_COST_COMPONENTS, {
    variables: {
      projectId,
      activityIds: leafActivityIds,
      estimateCodeId: currentEstimateCodeId,
      previousEstimateCodeId: prevEstimateCodeId,
      revisedBudgetEstimateCodeId,
      estimatesCurrencyId: selectedCurrencyScenario.estimatesCurrencyId,
      actualsCurrencyId: selectedCurrencyScenario.actualsCurrencyId,
      customCurrencyCode:
        selectedCurrencyScenario.id === CostCurrencyScenario.Custom ? selectedCurrencyScenario.currencyCode : null,
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.projectCostComponents) {
        let activityEditRows = allActivityEditRows;
        data.projectCostComponents.forEach(activity => {
          const components = activity.components.map(component => ({
            costComponentId: component.id,
            editValues: {
              revisedBudget: component.values.revisedBudget,
              currentEstimate: component.values.currentEstimate,
              estimatedHours: component.values.estimatedHours,
              originalBudget: component.values.originalBudget,
              asSold: component.values.asSold,
            },
            initialItem: component,
            isEditingBlocked: activity.isEditingBlocked,
            currencyRate: activity.currencyRate,
          }));
          activityEditRows = calculateComponentsAndSummaries(activityEditRows, activity.id, components);
        });
        setAllActivityEditRows(activityEditRows);
      }
    },
  });

  const { data: dataPointsData, loading: dataPointsLoading, error: dataPointsloadingErrors } = useQuery<{
    projectCostActivityDataPoints: ProjectCostActivityDataPoint[];
  }>(GET_PROJECT_COST_ACTIVITY_DATA_POINTS, {
    variables: { projectId, activityIds: leafActivityIds, currencyId: selectedCurrencyScenario.actualsCurrencyId },
  });

  const onClickSave = async () => {
    setSaving(true);
    setSavingError(false);

    const changes: CostActivityEstimateChange[] = allActivityEditRows
      .filter(
        activityRow =>
          activityRow.components &&
          activityRow.components.length > 0 &&
          activityRow.components.some(componentRow =>
            EDITABLE_COST_COMPONENTS.some(
              key =>
                componentRow.editValues[key] !== undefined &&
                componentRow.editValues[key] !== componentRow.initialItem.values[key]
            )
          )
      )
      .map(changedActivityRow =>
        produce(changedActivityRow, nextSate => {
          nextSate.components =
            nextSate.components &&
            nextSate.components.map(component => {
              const filteredComponent = cloneDeep(component);
              forEach(filteredComponent.editValues, (value, key) => {
                const column = key as ProjectCostEditColumnId;
                if (EDITABLE_COST_COMPONENTS.includes(column)) {
                  filteredComponent.editValues[column] =
                    value !== filteredComponent.initialItem.values[column] ? value : null;
                }
              });
              return filteredComponent;
            });
        })
      )
      .map(row => ({
        activityId: row.id,
        costComponents: row.components
          ? row.components.map(component => {
              const currencyRate = component.currencyRate;
              const values = component.editValues;
              return {
                costComponentId: component.costComponentId,
                revisedBudget: calculateCurrency(values.revisedBudget, currencyRate),
                currentEstimate: calculateCurrency(values.currentEstimate, currencyRate),
                estimatedHours: calculateCurrency(values.estimatedHours, currencyRate),
                originalBudget: calculateCurrency(values.originalBudget, currencyRate),
                asSold: calculateCurrency(values.asSold, currencyRate),
              };
            })
          : [],
      }));

    if (changes.length === 0) {
      onCancel();
    } else {
      const [success, messages] = await saveProjectCostComponents(
        client,
        projectId,
        estimateCodesSelectionState.currentEstimate ? estimateCodesSelectionState.currentEstimate.id : undefined,
        estimateCodesSelectionState.revisedBudget ? estimateCodesSelectionState.revisedBudget.id : undefined,
        estimateCodesSelectionState.asSold ? estimateCodesSelectionState.asSold.id : undefined,
        estimateCodesSelectionState.originalBudget ? estimateCodesSelectionState.originalBudget.id : undefined,
        selectedCurrencyScenario.estimatesCurrencyId,
        changes
      );
      if (success) {
        if (messages.length === 0) {
          onSave();
        } else {
          setMessagesDialog({ onOk: onSave, messages });
        }
      } else {
        setSavingError(true);
        setSaving(false);
      }
    }
  };

  const copyCommittedToCurrentColumn = () => {
    const updateSummaries = (activities: ProjectCostSummaryRow[]) => {
      const leaves = activities.filter(row => row.components !== undefined);
      leaves.forEach(
        leaf => (leaf.summaryValues = calculateSummaryValues((leaf.components || []).map(c => c.editValues)))
      );
      leaves.forEach(activity => {
        if (activity.components) updateParentSummary(activity.id, activities);
      });
    };
    if (data && data.projectCostComponents) {
      const newActivityRows = cloneDeep(allActivityEditRows);
      newActivityRows.forEach(activity => {
        activity.components &&
          activity.components.forEach(component => {
            if (!component.isEditingBlocked) {
              component.editValues.currentEstimate = component.initialItem.values.committed || 0;
            }
          });
      });
      updateSummaries(newActivityRows);
      setAllActivityEditRows(newActivityRows);
    }
  };

  const costComponents = data && data.projectCostComponents;
  const isLoading = loading || commentsLoading;
  const loadingFailed = loadingErrors || commentsError || !costComponents;
  const hasErrors = Object.keys(errors).some(v => errors[v]);
  const showSaveButton =
    !isLoading &&
    !loadingFailed &&
    !hasErrors &&
    allActivityEditRows.find(
      row =>
        row.components &&
        row.components
          .filter(component =>
            EDITABLE_COST_COMPONENTS.some(key => component[key] !== component.initialItem.values[key])
          )
          .map(({ ...others }) => others).length > 0
    );
  const noComponentsToEdit = !isLoading && costComponents && costComponents.every(item => item.components.length === 0);

  const content = isLoading ? (
    <Content>Loading...</Content>
  ) : loadingErrors ? (
    <Content>Loading failed</Content>
  ) : (
    <Content>
      <CostItemsTable
        projectId={projectId}
        activityRows={allActivityEditRows}
        comments={activityComments}
        setComments={setActivityComments}
        commentTypes={estimateCommentTypesResult ? estimateCommentTypesResult.estimateCommentTypes : []}
        estimateStatuses={estimateStatuses}
        estimateCodesSelectionState={estimateCodesSelectionState}
        userCanEdit={userCanEdit}
        currentEstEditBlocked={currentEstEditBlocked}
        selectedCurrencyScenario={selectedCurrencyScenario}
        setShowCopyDialog={setShowCopyDialog}
        onChangeComponentRows={(activityId, rows) =>
          setAllActivityEditRows(calculateComponentsAndSummaries(allActivityEditRows, activityId, rows))
        }
        errors={errors}
        setErrors={setErrors}
        selectedActivityId={selectedActivityId}
        setSelectedActivityId={setSelectedActivityId}
      />
    </Content>
  );

  const chart = dataPointsLoading ? (
    <ChartLoadingContainer>
      <FontAwesomeIcon icon={faSpinner} size="1x" spin={true} color={disabledGrey} />
    </ChartLoadingContainer>
  ) : dataPointsloadingErrors ? (
    <ChartLoadingContainer>Error loading chart data.</ChartLoadingContainer>
  ) : dataPointsData ? (
    <CostActualsCommittedChart
      selectedActivityId={selectedActivityId}
      dataPoints={dataPointsData.projectCostActivityDataPoints}
      contentWidth={contentWidth}
      activityRows={allActivityEditRows}
    />
  ) : null;

  const currencyDisplay = `${selectedCurrencyScenario.id}: ${selectedCurrencyScenario.currencyCode}`;
  return (
    <Root>
      <Wrapper>
        {saving ? <FullscreenSpinner text="Saving" /> : null}
        <ModalContainer ref={targetRef}>
          <ModalHeader>
            <Title>
              {projectId} {projectDescription}
            </Title>
            <SubTitle>Project: {projectRelatingKey1}</SubTitle>
            <SubTitle>{currencyDisplay}</SubTitle>
            <ButtonContainer>
              {showSaveButton ? (
                <IconButton
                  onClick={() => {
                    onClickSave();
                  }}
                  fontSize={"14px"}
                  hasText={true}
                >
                  Save
                  <FontAwesomeIcon icon={faSave} size="1x" color={settingGreen} />
                </IconButton>
              ) : null}
              <IconButton onClick={() => onCancel()} fontSize={"14px"} hasText={true}>
                Close
              </IconButton>
            </ButtonContainer>
            {savingError && <ErrorContainer>Error saving data.</ErrorContainer>}
          </ModalHeader>
          <ModalContent>
            {content}
            {noComponentsToEdit ? <div style={{ marginTop: "1em" }}>No cost components to edit</div> : <div />}
            {chart}
          </ModalContent>
        </ModalContainer>
        {showCopyDialog && (
          <CopyDialog
            onCancel={() => setShowCopyDialog(false)}
            onCopy={() => {
              copyCommittedToCurrentColumn();
              setShowCopyDialog(false);
            }}
          />
        )}
        {messagesDialog && (
          <MessagesDialog
            onOk={() => {
              messagesDialog.onOk();
              setMessagesDialog(undefined);
            }}
            title="Saving was successful, but..."
            messages={messagesDialog.messages}
          />
        )}
      </Wrapper>
    </Root>
  );
}

export default CostItemsEditor;

const Root = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background-color: ${disabledGrey};
  z-index: 2000;
`;

const ModalContainer = styled.div`
  max-height: 90vh;
  width: 85vw;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0 20px 20px;
  color: ${valmetGreyLight};

  .recharts-legend-item {
    cursor: pointer;
  }
`;

const ModalHeader = styled.div`
  background: #ffffff;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalContent = styled.div`
  overflow: auto;
`;

const Title = styled.div`
  font-size: 24px;
  color: ${defaultGrey};
  align-self: flex-start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SubTitle = styled.div`
  font-size: 18px;
  color: ${defaultGrey};
  margin-left: 20px;
`;

const Content = styled.div``;

const ErrorContainer = styled.div`
  margin-top: 20px;
`;

const ChartLoadingContainer = styled.div`
  margin-top: 40px;
`;
