import styled from "styled-components";
import {
  settingGreen,
  valmetDarkGreyTable,
  valmetGreyLight,
  valmetGreyREC,
  valmetGreyTable,
} from "../../../../../common/colors";
import {
  CostComponentEditRow,
  CurrencyScenario,
  EstimateCodeStatusId,
  ProjectCostEstimateValue,
} from "../../../../../common/types";
import { EDITABLE_COST_COMPONENTS } from "../constants";

export const getValue = (value: number | null | undefined): string => {
  if (value !== undefined && value !== null) return Math.round(value).toLocaleString("en-US").replace(/,/g, " ");
  else return "0";
};

export const getNumericValue = (value: number | null | undefined): number => {
  return value !== undefined && value !== null ? value : 0;
};

export const isEditingBlocked = (column: string, row: CostComponentEditRow): boolean => {
  return row.isEditingBlocked;
};

export const isColumnEditable = (
  estimateValue: ProjectCostEstimateValue | undefined,
  userCanEdit: boolean,
  selectedCurrencyScenario: CurrencyScenario,
  column?: string,
  value?: number | null
): boolean => {
  const hasValue = value !== undefined ? value !== null : undefined;
  if (!userCanEdit || !selectedCurrencyScenario.editable) {
    return false;
  }
  if (
    (column === EDITABLE_COST_COMPONENTS[0] ||
      column === EDITABLE_COST_COMPONENTS[3] ||
      column === EDITABLE_COST_COMPONENTS[4]) &&
    hasValue === false
  ) {
    return false;
  }
  if (estimateValue) {
    return estimateValue.estimateCodeStatus === EstimateCodeStatusId.Draft;
  } else return false;
};

export const SummaryDataRow = styled.tr<{
  childLevel?: number;
  isLeaf?: boolean;
  isComponent?: boolean;
  selected?: boolean;
}>`
  height: 28px;
  background: ${({ isLeaf, isComponent }) =>
    isComponent ? "" : isLeaf ? `${valmetGreyTable}` : `${valmetDarkGreyTable}`};
  ${({ selected }) => selected && `box-shadow: inset 2px 3px 0px ${settingGreen}, inset -2px -2px 0px ${settingGreen};`}

  td:first-child {
    min-width: 220px;
    font-weight: ${({ childLevel }) => !childLevel && "bold"};
    padding-right: 2px;
    padding-left: ${({ childLevel, isLeaf }) => (childLevel ? `${childLevel * 10 + 10 + (!isLeaf ? 0 : 5)}px` : "5px")};
    text-align: left;
  }
  td:last-child {
    border-right: 0;
  }
  td:not(:first-child) {
    white-space: nowrap;
  }
`;

export const DataRow = styled.tr<{ childLevel?: number; isLeaf?: boolean; isComponent?: boolean; selected?: boolean }>`
  background: ${({ isLeaf, isComponent }) =>
    isComponent ? "" : isLeaf ? `${valmetGreyTable}` : `${valmetDarkGreyTable}`};
  ${({ selected }) => selected && `box-shadow: inset 2px 3px 0px ${settingGreen}, inset -2px -2px 0px ${settingGreen};`}

  td:first-child {
    min-width: 220px;
    font-weight: ${({ childLevel }) => !childLevel && "bold"};
    padding-right: 4px;
    padding-left: ${({ childLevel, isLeaf }) =>
      childLevel ? `${childLevel * 10 + 10 + (!isLeaf ? 0 : 5)}px` : "10px"};
    text-align: left;
  }
  td:last-child {
    border-right: 0;
  }
  td:not(:first-child) {
    white-space: nowrap;
  }
`;

export const DataCell = styled.td<{ extraPadding?: boolean; warning?: boolean }>`
  border-right: 1px solid ${valmetGreyREC};
  text-align: right;
  padding-top: 4px;
  ${({ extraPadding }) => extraPadding && "padding-right:19px"};
  ${({ warning }) => warning && "color: red;"}
  padding-right: 5px;
  padding-left: 5px;
`;

export const ReadOnlyValue = styled.input<{ widthLimit?: number }>`
  color: ${valmetGreyLight};
  font-size: 11px;
  text-align: right;
  border: 0;
  background: transparent;
`;
